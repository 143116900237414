var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.user
        ? _c("v-skeleton-loader", {
            staticClass: "mb-6",
            attrs: { type: "card-avatar, article, actions" }
          })
        : _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { tile: "" } },
            [
              _c(
                "v-list-item",
                { attrs: { "three-line": "" } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { staticClass: "text-h5 mb-1" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.user.fullName) +
                            "\n        "
                        )
                      ]),
                      _c("v-list-item-subtitle", [
                        _vm._v(_vm._s(_vm.user.roleName))
                      ]),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          "Password expires in " +
                            _vm._s(_vm.user.passwordExpiresInDays) +
                            " days"
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-avatar",
                    { attrs: { tile: "", size: "164", color: "grey" } },
                    [
                      _c("v-icon", { attrs: { size: "164", dark: "" } }, [
                        _vm._v("\n          mdi-account\n        ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "500px", persistent: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mb-2",
                                    attrs: { color: "secondary", dark: "" }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v(
                                      "\n              mdi-lock-reset\n            "
                                    )
                                  ]),
                                  _vm._v(
                                    "\n            Change Password\n          "
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.dialog,
                        callback: function($$v) {
                          _vm.dialog = $$v
                        },
                        expression: "dialog"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "success white--text",
                              attrs: { "primary-title": "" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-h5 font-italic" },
                                [_vm._v("Change Password")]
                              )
                            ]
                          ),
                          _c(
                            "v-form",
                            { ref: "form" },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _vm.error
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "red--text font-weight-bold text-left"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { color: "red" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  mdi-alert-circle-outline\n                "
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.error) +
                                              "\n              "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "secondary",
                                      "prepend-icon": "mdi-lock-outline",
                                      "error-messages": _vm.oldPasswordErrors,
                                      required: "",
                                      name: "oldPassword",
                                      label: "Old Password",
                                      type: "password",
                                      success: _vm.oldPasswordSuccess
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.$v.oldPassword.$touch()
                                      },
                                      blur: function($event) {
                                        return _vm.$v.oldPassword.$touch()
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.submit()
                                      }
                                    },
                                    model: {
                                      value: _vm.oldPassword,
                                      callback: function($$v) {
                                        _vm.oldPassword = $$v
                                      },
                                      expression: "oldPassword"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "secondary",
                                      "prepend-icon": "mdi-lock-outline",
                                      "error-messages": _vm.newPasswordErrors,
                                      required: "",
                                      name: "newPassword",
                                      label: "New Password",
                                      type: "password",
                                      success: _vm.newPasswordSuccess,
                                      "error-count": "3"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.$v.newPassword.$touch()
                                      },
                                      blur: function($event) {
                                        return _vm.$v.newPassword.$touch()
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.submit()
                                      }
                                    },
                                    model: {
                                      value: _vm.newPassword,
                                      callback: function($$v) {
                                        _vm.newPassword = $$v
                                      },
                                      expression: "newPassword"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "secondary",
                                      "prepend-icon": _vm.confirmSuccess
                                        ? "mdi-check-all"
                                        : "mdi-lock-question",
                                      "error-messages":
                                        _vm.confirmPasswordErrors,
                                      required: "",
                                      name: "confirmPassword",
                                      label: "Confirm New Password",
                                      type: "password",
                                      success: _vm.confirmSuccess
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.$v.confirmPassword.$touch()
                                      },
                                      blur: function($event) {
                                        return _vm.$v.confirmPassword.$touch()
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.submit()
                                      }
                                    },
                                    model: {
                                      value: _vm.confirmPassword,
                                      callback: function($$v) {
                                        _vm.confirmPassword = $$v
                                      },
                                      expression: "confirmPassword"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "tertiary",
                                        disabled: _vm.isLoading()
                                      },
                                      on: { click: _vm.close }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Cancel\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "success",
                                        loading: _vm.isLoading()
                                      },
                                      on: { click: _vm.submit }
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v(
                                          "\n                  mdi-lock-reset\n                "
                                        )
                                      ]),
                                      _vm._v("Change Password\n              ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }