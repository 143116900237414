<template>
  <div>
    <v-skeleton-loader
      v-if="!user"
      class="mb-6"
      type="card-avatar, article, actions"
    />
    <v-card
      v-else
      tile
      class="mx-auto"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1">
            {{ user.fullName }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ user.roleName }}</v-list-item-subtitle>
          <v-list-item-subtitle>Password expires in {{ user.passwordExpiresInDays }} days</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar
          tile
          size="164"
          color="grey"
        >
          <v-icon
            size="164"
            dark
          >
            mdi-account
          </v-icon>
        </v-list-item-avatar>
      </v-list-item>

      <v-card-actions class="pa-4">
        <v-dialog
          v-model="dialog"
          max-width="500px"
          persistent
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              dark
              class="mb-2"
              v-on="on"
            >
              <v-icon left>
                mdi-lock-reset
              </v-icon>
              Change Password
            </v-btn>
          </template>
          <v-card>
            <v-card-title
              class="success white--text"
              primary-title
            >
              <span class="text-h5 font-italic">Change Password</span>
            </v-card-title>
            <v-form ref="form">
              <v-card-text>
                <p
                  v-if="error"
                  class="red--text font-weight-bold text-left"
                >
                  <v-icon
                    color="red"
                    class="mr-1"
                  >
                    mdi-alert-circle-outline
                  </v-icon>
                  {{ error }}
                </p>

                <v-text-field
                  v-model="oldPassword"
                  color="secondary"
                  prepend-icon="mdi-lock-outline"
                  :error-messages="oldPasswordErrors"
                  required
                  name="oldPassword"
                  label="Old Password"
                  type="password"
                  :success="oldPasswordSuccess"
                  @input="$v.oldPassword.$touch()"
                  @blur="$v.oldPassword.$touch()"
                  @keyup.enter="submit()"
                />

                <v-text-field
                  v-model="newPassword"
                  color="secondary"
                  prepend-icon="mdi-lock-outline"
                  :error-messages="newPasswordErrors"
                  required
                  name="newPassword"
                  label="New Password"
                  type="password"
                  :success="newPasswordSuccess"
                  error-count="3"
                  @input="$v.newPassword.$touch()"
                  @blur="$v.newPassword.$touch()"
                  @keyup.enter="submit()"
                />

                <v-text-field
                  v-model="confirmPassword"
                  color="secondary"
                  :prepend-icon="confirmSuccess ? 'mdi-check-all' : 'mdi-lock-question'"
                  :error-messages="confirmPasswordErrors"
                  required
                  name="confirmPassword"
                  label="Confirm New Password"
                  type="password"
                  :success="confirmSuccess"
                  @input="$v.confirmPassword.$touch()"
                  @blur="$v.confirmPassword.$touch()"
                  @keyup.enter="submit()"
                />
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="tertiary"
                  :disabled="isLoading()"
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="success"
                  :loading="isLoading()"
                  @click="submit"
                >
                  <v-icon left>
                    mdi-lock-reset
                  </v-icon>Change Password
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import {
  atLeastOneDigit,
  atLeastOneUppercase,
  atLeastOneLowercase,
  atLeastOneSpecial
} from '@/shared/validators'
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserProfile',

  mixins: [validationMixin],

  validations: {
    oldPassword: { required },
    newPassword: {
      required,
      minLength: minLength(8),
      atLeastOneDigit,
      atLeastOneUppercase,
      atLeastOneLowercase,
      atLeastOneSpecial
    },
    confirmPassword: { sameAs: sameAs('newPassword') }
  },

  data: () => ({
    // user: null,
    dialog: false,
    error: null,
    message: null,
    oldPassword: null,
    newPassword: null,
    confirmPassword: null
  }),

  computed: {
    ...mapGetters(['isLoading']),
    // ...mapGetters("auth", ["getUser"]),
    user: {
      get () {
        return this.$store.getters['auth/getUser']()
      }
    },
    oldPasswordErrors () {
      const errors = []
      if (!this.$v.oldPassword.$dirty) return errors
      !this.$v.oldPassword.required && errors.push('Old Password is required')
      return errors
    },
    newPasswordErrors () {
      const errors = []
      if (!this.$v.newPassword.$dirty) return errors
      !this.$v.newPassword.required && errors.push('New Password is required')
      !this.$v.newPassword.minLength &&
        errors.push('New Password must be at least 8 characters')
      !this.$v.newPassword.atLeastOneLowercase &&
        errors.push('New Password must have at least 1 lowercase letter')
      !this.$v.newPassword.atLeastOneUppercase &&
        errors.push('New Password must have at least 1 uppercase letter')
      !this.$v.newPassword.atLeastOneDigit &&
        errors.push('New Password must have at least 1 number')
      !this.$v.newPassword.atLeastOneSpecial &&
        errors.push('New Password must have at least 1 special character')
      return errors
    },
    confirmPasswordErrors () {
      const errors = []
      if (!this.$v.confirmPassword.$dirty) return errors
      !this.$v.confirmPassword.sameAs && errors.push('Passwords must match')
      return errors
    },
    oldPasswordSuccess () {
      return this.oldPasswordErrors.length === 0 && this.oldPassword !== null
    },
    newPasswordSuccess () {
      return this.newPasswordErrors.length === 0 && this.newPassword !== null
    },
    confirmSuccess () {
      return (
        this.confirmPasswordErrors.length === 0 && this.confirmPassword !== null
      )
    }
  },

  created () {
    // this.user = this.getUser();
  },

  methods: {
    ...mapActions('auth', ['passwordChange']),
    async submit () {
      this.error = null
      this.message = null

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const data = await this.passwordChange({
        userId: this.user.userId,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      })

      if (data.errorResponse) {
        this.error = data.errorResponse
        return
      }

      this.close(true)
    },
    close (reset) {
      this.dialog = false
      setTimeout(() => {
        if (reset) this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
